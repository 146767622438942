import { Button, Checkbox, Icon, Input, Radio } from 'antd';
import React, { FC, Fragment, useState } from 'react';

import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

import { useSelector } from 'react-redux';
import UploadComponent from '../../../../challenge/Components/UploadComponent';
import { UPLOAD_ACTION_IMAGE } from '../../../../shared';
import FormItem from 'antd/lib/form/FormItem';
import { ENABLE_BANNER, ENABLE_MEDIA, MEDIA_URL } from './constants';
import { isValidUrl } from './validate.fields';

import './WebeatHeroSections.css';

export const RenderHeroFields: FC<{
  handleChange: (key: string, value: any) => void;
  fieldConfig: {
    key: string;
    label: string;
    subLabel?: string;
    type: string;
    placeholder: string;
    required?: boolean;
    maxLength?: number;
  };
  value: any;
  constentState?: any;
  homeId?: string;
}> = ({ handleChange, fieldConfig, value, constentState, homeId }) => {
  const { auth } = useSelector((state: any) => state);

  const [, forceUpdate] = useState<number>(0);

  // Error length handling
  let error;
  if (fieldConfig?.maxLength) {
    error = value?.length > fieldConfig?.maxLength!;
  }

  if (fieldConfig.type === 'media-banner-selector') {
    if (
      (fieldConfig.key === ENABLE_BANNER &&
        (value === null || value === undefined)) ||
      (fieldConfig.key === ENABLE_MEDIA &&
        (value === null || value === undefined))
    ) {
      handleChange(ENABLE_BANNER, true);
    }

    return (
      <>
        {fieldConfig.key === ENABLE_BANNER && (
          <div className="webeat-home-section-hero-spacer"></div>
        )}
        <Checkbox
          checked={value}
          onChange={(e) => handleChange(fieldConfig.key, e.target.checked)}>
          {fieldConfig.label}
        </Checkbox>
      </>
    );
  }

  if (fieldConfig.type === 'url-input') {
    return (
      <Fragment>
        <div className="webeat-home-section-hero-spacer"></div>
        <Title level={3}>{fieldConfig.label}</Title>
        <Text>{fieldConfig.subLabel}</Text>

        <FormItem
          style={{
            maxWidth: 400,
          }}
          colon={false}
          validateStatus={!isValidUrl(value) ? 'error' : '' ? 'error' : ''}
          required={fieldConfig.required}
          help={!isValidUrl(value) && 'The URL format is not valid.'}>
          <Input
            value={value}
            onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
            placeholder={fieldConfig.placeholder}
          />
        </FormItem>
      </Fragment>
    );
  }

  if (fieldConfig.type === 'input' && constentState[ENABLE_BANNER]) {
    return (
      <FormItem
        style={{
          maxWidth: 400,
        }}
        className="webeat-home-section-hero-input"
        colon={false}
        validateStatus={
          error || (fieldConfig.required && !value) ? 'error' : ''
        }
        required={fieldConfig.required}
        help={
          (error &&
            `It should contain a maximum caracters of: ${fieldConfig?.maxLength}.`) ||
          (fieldConfig.required && !value && `It should required.`)
        }>
        <Input
          value={value}
          onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
          placeholder={fieldConfig.placeholder}
        />
      </FormItem>
    );
  }

  if (fieldConfig.type === 'checkbox' && constentState[ENABLE_BANNER]) {
    return (
      <>
        <Checkbox
          checked={value}
          onChange={(e) => handleChange(fieldConfig.key, e.target.checked)}>
          {fieldConfig.label}
        </Checkbox>
      </>
    );
  }

  if (fieldConfig.type === 'title' && constentState[ENABLE_BANNER]) {
    return (
      <Fragment>
        <div className="webeat-home-section-hero-spacer"></div>
        <Title level={3}>{fieldConfig.label}</Title>
        <Text>{fieldConfig.subLabel}</Text>
      </Fragment>
    );
  }

  if (fieldConfig.type === 'title-media') {
    return (
      <Fragment>
        <div className="webeat-home-section-hero-spacer"></div>
        <Title level={3}>{fieldConfig.label}</Title>
        <Text>{fieldConfig.subLabel}</Text>
      </Fragment>
    );
  }

  if (fieldConfig.type === 'upload') {
    if (
      value === undefined ||
      (value === null && constentState[MEDIA_URL].checked === null) ||
      (value === undefined && constentState[MEDIA_URL].checked === undefined)
    ) {
      value = {
        checked: true,
        desktopLarge: null,
        desktop: null,
        mobile: null,
      };
    }

    return (
      <Fragment>
        <br />
        <Checkbox
          checked={value['checked']}
          onChange={(e) =>
            handleChange(fieldConfig.key, {
              desktopLarge: value.desktopLarge,
              checked: e.target.checked ? true : false,
              desktop: value.desktop,
              mobile: value.mobile,
            })
          }>
          {fieldConfig.label}
        </Checkbox>
        <br />
        {value['checked'] && (
          <Fragment>
            <br />
            <div className="webeat-home-section-hero-block">
              <UploadComponent
                format={'image'}
                token={auth.accessToken}
                uploadActions={[UPLOAD_ACTION_IMAGE]}
                materialType={3}
                primaryEntityId={homeId}
                value={value['desktopLarge']}
                module="home"
                handleChangeField={(data: any) => {
                  handleChange(fieldConfig.key, {
                    checked: value.checked,
                    desktopLarge: data,
                    desktop: value.desktop,
                    mobile: value.mobile,
                  });
                  forceUpdate(Math.random());
                }}
                setValue={function (
                  valueItem?: string | null | undefined,
                ): void {
                  handleChange(fieldConfig.key, {
                    checked: value.checked,
                    desktopLarge: valueItem,
                    desktop: value.desktop,
                    mobile: value.mobile,
                  });
                  forceUpdate(Math.random());
                }}>
                <Button
                  className="uploadButton uploadButton__margin-top"
                  disabled={false}>
                  <Icon type="upload" />
                  <span>Upload Desktop Large</span>
                </Button>
              </UploadComponent>
              <br />
            </div>
            <div className="webeat-home-section-hero-block">
              <UploadComponent
                format={'image'}
                token={auth.accessToken}
                uploadActions={[UPLOAD_ACTION_IMAGE]}
                materialType={3}
                value={value['desktop']}
                primaryEntityId={homeId}
                module="home"
                handleChangeField={(data: any) => {
                  handleChange(fieldConfig.key, {
                    checked: value.checked,
                    desktop: data,
                    desktopLarge: value.desktopLarge,
                    mobile: value.mobile,
                  });
                  forceUpdate(Math.random());
                }}
                setValue={function (
                  valueItem?: string | null | undefined,
                ): void {
                  handleChange(fieldConfig.key, {
                    checked: value.checked,
                    desktop: valueItem,
                    desktopLarge: value.desktopLarge,
                    mobile: value.mobile,
                  });
                  forceUpdate(Math.random());
                }}>
                <Button
                  className="uploadButton uploadButton__margin-top"
                  disabled={false}>
                  <Icon type="upload" />
                  <span>Upload Desktop</span>
                </Button>
              </UploadComponent>
              <br />
            </div>
            <div className="webeat-home-section-hero-block">
              <UploadComponent
                format={'image'}
                token={auth.accessToken}
                uploadActions={[UPLOAD_ACTION_IMAGE]}
                materialType={3}
                value={value['mobile']}
                primaryEntityId={homeId}
                module="home"
                handleChangeField={(data: any) => {
                  handleChange(fieldConfig.key, {
                    checked: value.checked,
                    mobile: data,
                    desktop: value.desktop,
                    desktopLarge: value.desktopLarge,
                  });
                  forceUpdate(Math.random());
                }}
                setValue={function (
                  valueItem?: string | null | undefined,
                ): void {
                  handleChange(fieldConfig.key, {
                    checked: value.checked,
                    desktop: value.desktop,
                    desktopLarge: value.desktopLarge,
                    mobile: valueItem,
                  });
                  forceUpdate(Math.random());
                }}>
                <Button
                  className="uploadButton uploadButton__margin-top"
                  disabled={false}>
                  <Icon type="upload" />
                  <span>Upload Mobile</span>
                </Button>
              </UploadComponent>
              <br />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }

  if (fieldConfig.type === 'media-url') {
    if (value === null || value === undefined) {
      value = {
        checked: false,
        desktopLarge: null,
        desktop: null,
        mobile: null,
      };
    }

    return (
      <>
        <Checkbox
          checked={value['checked']}
          onChange={(e) =>
            handleChange(fieldConfig.key, {
              checked: e.target.checked ? true : false,
              desktopLarge: value['desktopLarge'],
              desktop: value['desktop'],
              mobile: value['mobile'],
            })
          }>
          {fieldConfig.label}
        </Checkbox>

        {value['checked'] && (
          <Fragment>
            <br />
            <FormItem
              className="webeat-home-section-hero-block"
              colon={false}
              validateStatus={!isValidUrl(value['desktopLarge']) ? 'error' : ''}
              required={fieldConfig.required}
              help={
                !isValidUrl(value['desktopLarge'])
                  ? 'The URL format is not valid.'
                  : ''
              }
              label={'Desktop Large'}>
              <Input
                value={value['desktopLarge']}
                onChange={(e) =>
                  handleChange(fieldConfig.key, {
                    checked: value['checked'],
                    desktopLarge: e.target.value,
                    desktop: value['desktop'],
                    mobile: value['mobile'],
                  })
                }
                placeholder={'Desktop Large'}
              />
            </FormItem>

            <FormItem
              className="webeat-home-section-hero-block"
              colon={false}
              validateStatus={!isValidUrl(value['desktop']) ? 'error' : ''}
              required={fieldConfig.required}
              help={
                !isValidUrl(value['desktop'])
                  ? 'The URL format is not valid.'
                  : ''
              }
              label={'Desktop'}>
              <Input
                value={value['desktop']}
                onChange={(e) =>
                  handleChange(fieldConfig.key, {
                    checked: value['checked'],
                    desktopLarge: value['desktopLarge'],
                    desktop: e.target.value,
                    mobile: value['mobile'],
                  })
                }
                placeholder={'Desktop'}
              />
            </FormItem>

            <FormItem
              className="webeat-home-section-hero-block"
              colon={false}
              validateStatus={!isValidUrl(value['mobile']) ? 'error' : ''}
              required={fieldConfig.required}
              help={
                !isValidUrl(value['mobile'])
                  ? 'The URL format is not valid.'
                  : ''
              }
              label={'Mobile'}>
              <Input
                value={value['mobile']}
                onChange={(e) =>
                  handleChange(fieldConfig.key, {
                    checked: value['checked'],
                    desktopLarge: value['desktopLarge'],
                    desktop: value['desktop'],
                    mobile: e.target.value,
                  })
                }
                placeholder={'Mobile'}
              />
            </FormItem>
            <br />
          </Fragment>
        )}
      </>
    );
  }

  if (fieldConfig.type === 'text-alignment' && constentState[ENABLE_BANNER]) {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    return (
      <Radio.Group
        onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
        value={value || 'left'}>
        <Radio value={'left'} style={radioStyle}>
          Left aligned (Desktop), up aligned (Mobile)
        </Radio>
        <Radio value={'center'} style={radioStyle}>
          Center aligned (Desktop), down aligned (Mobile)
        </Radio>
      </Radio.Group>
    );
  }

  if (fieldConfig.type === 'text-color' && constentState[ENABLE_BANNER]) {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    return (
      <Radio.Group
        onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
        value={value || '#FFFFF'}>
        <Radio value={'#FFFFF'} style={radioStyle}>
          White (#FFFFFF)
        </Radio>
        <Radio value={'#414141'} style={radioStyle}>
          Black (#414141)
        </Radio>
      </Radio.Group>
    );
  }

  return null;
};
