export const MEDIA_FILE = 'mediaFile';
export const MEDIA_URL = 'mediaUrl';
export const ENABLE_BANNER = 'enableBanner';
export const ENABLE_MEDIA = 'enableMedia';
export const URL_LEGAL_LINK = 'urlLegaLink';
export const HERO_TITLE = 'title';
export const PRE_TITLE = 'preTitle';
export const PARAGRAPH = 'paragraph';
export const CTA = 'cta';
export const LEGAL_TEXT = 'legalText';
export const TEXT_ALIGN = 'textAlign';
export const TEXT_COLOR = 'textColor';

export const HERO_TITLE_MAX_LENGTH = 40;
export const PRE_TITLE_MAX_LENGTH = 25;
export const PARAGRAPH_MAX_LENGTH = 80;
export const LEGAL_TEXT_MAX_LENGTH = 100;
export const CTA_MAX_LENGTH = 20;
