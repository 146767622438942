import { List, notification } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { IRow } from '../app/AppInterfaces';
import { isConPlatform, isPINPlatform, isWebeatPlatform } from '../utils';
import { IResourceDetail } from './ChallengeInterfaces';
import { ChallengeTypeEnum, ResourceTypeDetailEnum } from './Enums';
import { checkEmailValue } from './AdminVisibility/AdminVisibilityForm';

export const renderErrors = (
  errorsArray: { email: string; message: string }[],
  intl: any,
) => {
  const dataErrorsNotFount: { email: string; message: string }[] = [];
  const dataErrorsFormat: { email: string; message: string }[] = [];

  errorsArray.map((_error: { email: string; message: string }) => {
    if (!checkEmailValue(_error.email)) dataErrorsFormat.push(_error);
    else dataErrorsNotFount.push(_error);
    return { dataErrorsFormat, dataErrorsNotFount };
  });

  const dataErrorNotFoundFormat = dataErrorsNotFount.map(
    (_error: { email: string; message: string }) => (
      <div>
        {_error.email} - Reason: {_error.message}
      </div>
    ),
  );

  const dataErrorFormatFormat = dataErrorsFormat.map(
    (_error: { email: string; message: string }) => (
      <div>{_error.email} - Has wrong format</div>
    ),
  );
  dataErrorsFormat.length > 0 &&
    notification.warn({
      message: intl.formatMessage({
        id: 'challenge.visibility.wrongFormat',
      }),
      duration: 3,
      description: (
        <List
          size="small"
          renderItem={(item: JSX.Element) => <List.Item>{item}</List.Item>}
          dataSource={dataErrorFormatFormat}
        />
      ),
    });

  dataErrorsNotFount.length > 0 &&
    notification.warn({
      message: intl.formatMessage({
        id: 'challenge.visibility.wrongEmail',
      }),
      duration: 3,
      description: (
        <List
          size="small"
          renderItem={(item: JSX.Element) => <List.Item>{item}</List.Item>}
          dataSource={dataErrorNotFoundFormat}
        />
      ),
    });
};

// FIXME: BORRAR: categories AHORA ES filter
export const getCategoriesValues = (values: IRow) => {
  if (!values) return [];
  const selectValues = values?.categories || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idCategory - b.idCategory)
    .map((value: IRow) => value.idCategory.toString());
};

export const getFiltersValues = (values: IRow) => {
  if (!values) return [];
  const selectValues = values?.filters || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idFilter - b.idFilter)
    .map((value: IRow) => value.idFilter.toString());
};

export const getRoleTypeValues = (values: IRow) => {
  if (isWebeatPlatform() || isPINPlatform()) {
    return values?.roles || [];
  }

  if (!values) return [];
  const selectValues = values?.roles || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idRoleType - b.idRoleType)
    .map((value: IRow) => value.idRoleType.toString());
};

export const getSpecialtiesValues = (values: IRow) => {
  if (!values) return [];
  const selectValues = values?.specialties || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idSpecialty - b.idSpecialty)
    .map((value: IRow) => value.idSpecialty.toString());
};

export const getTagValues = (values: IRow) => {
  if (isWebeatPlatform()) {
    return values?.tags || [];
  }

  if (!values) return [];
  const selectValues = values?.tags || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idTag - b.idTag)
    .map((value: IRow) => value.idTag.toString());
};

export const getDetail = (
  resourceDetailList: IResourceDetail[],
  idResourceType: ResourceTypeDetailEnum,
): [IResourceDetail | null, number | null] => {
  let detailIndex: number | null = null;
  const detail =
    resourceDetailList.find(
      ({ idResourceTypeD: { idResourceTypeD } }, index) => {
        if (idResourceTypeD === idResourceType) {
          detailIndex = index;
          return true;
        }
        return false;
      },
    ) || null;

  return [detail, detailIndex];
};

export const isConExperience = (
  idChallengeType: number,
  checkIsExperience?: boolean,
) => {
  if (checkIsExperience)
    return isConPlatform() && idChallengeType === ChallengeTypeEnum.EXPERIENCE;

  return isConPlatform();

  // return idChallengeType in ChallengeTypeEnum;
};

// FIXME: BORRAR: categories AHORA ES filter
export const showCategories = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showFilters = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showBrands = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showContentTypes = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showRoleType = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showSpecialties = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const validateSlug = (slug: string): boolean => {
  const slugRegex = new RegExp('^[a-z0-9-]+$', 'g');

  return slugRegex.test(slug);
};

export const hasIzoomCodeChanged = (code: string) => {
  //Regex to check if the code has a format similar to YYYY_MM_SOMETHING_talk so we know if it has a valid iZoomCode
  const correctIzoomCodeRegex =
    /^[A-Za-z0-9]{4}_[A-Za-z0-9]{2}_.+_[A-Za-z0-9]+$/;

  if (correctIzoomCodeRegex.test(code)) return true;

  return false;
};
