import { Input, Switch } from 'antd';
import React, { FC } from 'react';
import { HomeSectionList, HomeSectionListKey } from './WebeatHomeSectionEnum';
import { WebeatHomeSectionsConfig } from './WebeatHomeSectionsConfig';

import { RenderHeroFields } from './components';
import { heroHandler } from './components/hero/hero.handler';

import Text from 'antd/lib/typography/Text';

const RenderField: FC<{
  handleChange: (key: string, value: any) => void;
  fieldConfig: {
    key: string;
    label: string;
    subLabel?: string;
    type: string;
    placeholder: string;
    required?: boolean;
  };
  value: any;
  constentState?: any;
}> = ({ handleChange, fieldConfig, value, constentState }) => {
  if (fieldConfig.type === 'input') {
    return (
      <div
        style={{
          maxWidth: 400,
        }}>
        <Input
          value={value}
          onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
          placeholder={fieldConfig.placeholder}
        />
      </div>
    );
  }

  return null;
};

export const WebeatHomeSectionFields: FC<{
  sectionKey: HomeSectionListKey;
  handleChange: (
    sectionKey: HomeSectionListKey,
    active: boolean,
    content: string,
  ) => void;
  active: boolean;
  content: string;
  homeId?: string;
}> = ({ sectionKey, handleChange, active, content, homeId }) => {
  const homeSectionListItem = HomeSectionList[sectionKey];
  const [activeState, setActive] = React.useState(!!active);
  const [contentState, setContent] = React.useState(JSON.parse(content));

  const handleChangeActive = (value: boolean) => {
    setActive(value);
    handleChange(sectionKey, value, JSON.stringify(contentState));
  };

  const handleChangeContent = (key: string, value: any) => {
    heroHandler(key, contentState);

    setContent({
      ...contentState,
      [key]: value,
    });

    handleChange(
      sectionKey,
      activeState,
      JSON.stringify({
        ...contentState,
        [key]: value,
      }),
    );
  };

  return (
    <div className="webeat-home-sections__panel--content">
      <div
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
        }}>
        <Switch
          checked={activeState}
          onChange={(e) => handleChangeActive(e)}
          disabled={homeSectionListItem.required}
        />
        <Text>Active</Text>
      </div>

      {/* Hero */}
      {sectionKey === 'Hero' &&
        WebeatHomeSectionsConfig['Hero'].fields.map((fieldConfig) => {
          const fieldValue = contentState[fieldConfig.key];
          return (
            <div key={fieldConfig.key}>
              <RenderHeroFields
                fieldConfig={fieldConfig}
                handleChange={handleChangeContent}
                value={fieldValue}
                constentState={contentState}
                homeId={homeId}
              />
            </div>
          );
        })}
      {/* Categories */}
      {sectionKey === 'Categories' &&
        WebeatHomeSectionsConfig['Categories'].fields.map(
          (fieldConfigCategory) => {
            const fieldValue = contentState[fieldConfigCategory.key];

            return (
              <div
                key={fieldConfigCategory.key}
                className="webeat-home-sections__field">
                {fieldConfigCategory.type === 'text' && (
                  <label className="webeat-home-sections__label">
                    {fieldConfigCategory.label}
                  </label>
                )}

                <RenderField
                  fieldConfig={fieldConfigCategory}
                  handleChange={handleChangeContent}
                  value={fieldValue}
                  constentState={contentState}
                />
              </div>
            );
          },
        )}
    </div>
  );
};
