import {
  MEDIA_FILE,
  MEDIA_URL,
  PRE_TITLE,
  URL_LEGAL_LINK,
  HERO_TITLE,
  PARAGRAPH,
  CTA,
  LEGAL_TEXT,
  ENABLE_BANNER,
  ENABLE_MEDIA,
  TEXT_ALIGN,
  TEXT_COLOR,
  PRE_TITLE_MAX_LENGTH,
  HERO_TITLE_MAX_LENGTH,
  PARAGRAPH_MAX_LENGTH,
  CTA_MAX_LENGTH,
  LEGAL_TEXT_MAX_LENGTH,
} from './constants';

export type HeroComponentType =
  | 'media-banner-selector'
  | 'title'
  | 'checkbox'
  | 'input'
  | 'url-input'
  | 'subTitle'
  | 'upload'
  | 'media-url'
  | 'text'
  | 'check'
  | 'text-alignment'
  | 'text-color'
  | 'title-media';

interface Field {
  key: string;
  label: string;
  subLabel?: string;
  type: HeroComponentType;
  placeholder: string;
  required?: boolean;
  maxLength?: number;
}

export const heroSectionFields: Field[] = [
  {
    key: ENABLE_BANNER,
    label:
      'Set up a banner *RECOMENDED* (you have to introduce all the settings and content (title, descripction, cta...)',
    type: 'media-banner-selector',
    placeholder: '',
    required: false,
  },
  {
    key: ENABLE_MEDIA,
    label:
      'Enter a media file *NOT RECOMENDED* ⚠️  (This option does not comply with the principles of usability, accessibility and SEO)',
    type: 'media-banner-selector',
    placeholder: '',
    required: false,
  },

  {
    key: 'title',
    label: '*Media (image or video)',
    subLabel:
      'It needs 3 differents image/video: Desktop Large, Desktop and Mobile. Introduce an URL or upload a file',
    type: 'title-media',
    placeholder: '',
    required: false,
  },
  {
    key: MEDIA_FILE,
    label: 'Upload file',
    type: 'upload',
    placeholder: '',
    required: false,
  },
  {
    key: MEDIA_URL,
    label: 'Enter an url',
    type: 'media-url',
    placeholder: '',
    required: false,
  },

  {
    key: URL_LEGAL_LINK,
    label: '*URL',
    subLabel: 'Introduce an URL',
    type: 'url-input',
    placeholder: 'URL Legal link',
    required: true,
  },

  {
    key: 'text-alignment',
    label: '*Text Alignment',
    subLabel: 'Select the text position',
    type: 'title',
    placeholder: '',
    required: false,
  },

  {
    key: TEXT_ALIGN,
    label: '',
    type: 'text-alignment',
    placeholder: '',
    required: true,
  },

  {
    key: 'text-color',
    label: '*Text Color',
    subLabel: 'Select the text color',
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: TEXT_COLOR,
    label: '',
    type: 'text-color',
    placeholder: '',
    required: true,
  },

  {
    key: 'pre-title',
    label: 'PreTitle (optional)',
    subLabel: 'It should contain a maximum caracters of: 25.',
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: PRE_TITLE,
    label: '',
    type: 'input',
    placeholder: 'Enter a Pretitle text here',
    required: false,
    maxLength: PRE_TITLE_MAX_LENGTH,
  },

  {
    key: 'title',
    label: '*Title',
    subLabel: 'It should contain a maximum caracters of: 40.',
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: HERO_TITLE,
    label: '',
    type: 'input',
    placeholder: 'Enter a Title e text here',
    required: true,
    maxLength: HERO_TITLE_MAX_LENGTH,
  },

  {
    key: 'paragraph',
    label: 'Paragraph (optional)',
    subLabel: 'It should contain a maximum caracters of: 80.',
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: PARAGRAPH,
    label: '',
    type: 'input',
    placeholder: 'Enter Paragraph text here',
    required: false,
    maxLength: PARAGRAPH_MAX_LENGTH,
  },

  {
    key: 'cta',
    label: '*CTA',
    subLabel: 'It should contain a maximum caracters of: 20.',
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: CTA,
    label: '',
    type: 'input',
    placeholder: 'Enter a CTA text here',
    required: true,
    maxLength: CTA_MAX_LENGTH,
  },

  {
    key: 'legalText-title',
    label: 'Legal text  (optional)',
    subLabel:
      'It should contain a maximum caracters of: 100.\n/  Si hay alguna parte del texto que lleva un link añádelo ente corchetes, por ejemplo: Para más información consulta nuestros [Términos y condiciones]',
    type: 'title',
    placeholder: '',
    required: false,
  },
  {
    key: 'legalText-subtitle2',
    label:
      'Si hay alguna parte del texto que lleva un link añádelo ente corchetes, por ejemplo: Para más información consulta nuestros [Términos y condiciones]',
    type: 'subTitle',
    placeholder: '',
    required: false,
  },
  {
    key: 'legalText-text',
    label: 'Enter a Legal text here',
    type: 'subTitle',
    placeholder: '',
    required: false,
  },
  {
    key: LEGAL_TEXT,
    label: '',
    type: 'input',
    placeholder: 'URL Legal link',
    required: false,
    maxLength: LEGAL_TEXT_MAX_LENGTH,
  },
];
